<template>
  <div id="detail-z">
    <TABS :list="tabsArr"></TABS>
    <TABLE :switchChange='onCallbackSwitch' :buttonChange='onCallbackButton' :isShowSele="false" :list="tableArr" :data="dataArr"></TABLE>
    <PAGE :pageChange='onCallbackPage' :limitChange='onCallbackLimit' :count="count"></PAGE>
  </div>
</template>

<script>
import TABS from '@/components/tabs' // 横向 button
import TABLE from '@/components/table' // 列表
import PAGE from '@/components/page' // 分页
import { getDictConfig, updDictConfigStatus, delDictConfigStatus } from '@/api/system'
export default {
  name: 'dictConfig',
  components: {
    TABS,
    TABLE,
    PAGE,
  },
  data() {
    return {
      tabsArr: [
        {
          type: 'primary',
          name: '添加配置',
          func: '',
        },
      ], // 标签列表
      tableArr: [
        {
          prop: 'id',
          label: 'ID',
          width: '120',
        },
        {
          prop: 'info',
          label: '分类名称',
          width: '800',
        },
        {
          prop: 'menu_name',
          label: '分类字段',
          width: '200',
        },
        {
          prop: 'status',
          label: '状态',
          width: '50',
          type: 'switch',
        },
        {
          prop: '',
          label: '操作',
          width: '',
          type: 'but',
        },
      ], // 表头列表
      dataArr: [], // 数据列表
      butnArr: [
        {
          type: '',
          name: '编辑',
          func: '',
        },
        {
          type: 'danger',
          name: '删除',
          func: 'removeDictConfigStatus',
        },
      ], // 数据-操作列表
      count: 0, // 总页数
      formData: {
        page: 1,
        limit: 10,
        id: '',
      },
    }
  },
  props: {
    id: {
      type: String,
      default() {
        return ''
      },
    },
  },
  created() {},
  mounted() {
    this._loadData()
  },
  methods: {
    _loadData: function () {
      this.formData.id = this.id
      getDictConfig(this.formData).then((res) => {
        const { result } = res

        this.count = result.count
        result.data.forEach(item => {
          item.buttons = JSON.parse(JSON.stringify(this.butnArr))
          item.switch = {
            status: item.status === 1,
            func: 'changeStatus',
          }
        })
        this.dataArr = result.data
      })
    },

    // 状态 开关
    changeStatus: function(item) {
      const data = {
        id: item.id,
        status: item.status === 1 ? 0 : 1,
      }
      updDictConfigStatus(data).then(res => {
        this.$toast(res.message)
        this._loadData()
      })
    },

    // 删除围栏
    removeDictConfigStatus: function({ id }) {
      delDictConfigStatus(id).then(res => {
        this.$toast(res.message)
        this._loadData()
      })
    },

    // 收到搜索回调
    onCallbackSearch: function(data) {
      const { fenceName, fenceProject } = data
      this.formData.name = fenceName
      this.formData.p_id = fenceProject
      this._loadData()
    },

    // 收到 table内方法 回调
    onCallbackButton: function(item, func) {
      this[func](item)
    },
    // 收到 表格内 switch 方法回调
    onCallbackSwitch: function(item, func) {
      this[func](item)
    },

    // 收到改变页码回调
    onCallbackPage: function(page) {
      this.formData.page = page
      this._loadData()
    },
    // 收到改变一页数量回调
    onCallbackLimit: function(limit) {
      this.formData.limit = limit
      this._loadData()
    },
  },
}
</script>

<style lang="stylus" scoped>

</style>
