<template>
  <div>
    <div v-if="!showType">
      <SEARCH :searchChange='onCallbackSearch' :list="searchArr"></SEARCH>
      <TABS :list="tabsArr"></TABS>
      <TREETABLE :switchChange='onCallbackSwitch' :buttonChange='onCallbackButton' :isShowSele="false" :list="tableArr" :data="dataArr"></TREETABLE>
      <PAGE  :pageChange='onCallbackPage' :limitChange='onCallbackLimit' :count="count"></PAGE>
    </div>
    <div v-if="showType === 'dictConfig'">
      <NAVBAR :backChange='onCallbackBack' title="配置列表"></NAVBAR>
      <dict-config :id='configId'></dict-config>
    </div>
  </div>
</template>

<script>
import SEARCH from '@/components/search' // 顶部搜索
import TABS from '@/components/tabs' // 横向 button
import TREETABLE from '@/components/treeTable' // 列表
import PAGE from '@/components/page' // 分页
import NAVBAR from '@/components/navbar' // 导航
import dictConfig from './components/dictConfig' // 配置列表
import { getDict, updDictStatus, delDict } from '@/api/system'
export default {
  name: 'System/Dict',
  components: {
    SEARCH,
    TABS,
    TREETABLE,
    PAGE,
    NAVBAR,
    dictConfig,
  },
  data() {
    return {
      searchArr: [
        {
          name: '状态',
          type: 'picker',
          options: [],
          key: 'type',
        },
        {
          name: '分类名称',
          type: 'input',
          options: [],
          key: 'title',
        },
      ], // 搜索列表
      tabsArr: [
        {
          type: 'primary',
          name: '添加分类',
          func: '',
        },
      ], // 标签列表
      tableArr: [
        {
          prop: 'id',
          label: 'ID',
          width: '120',
        },
        {
          prop: 'title',
          label: '分类名称',
          width: '800',
        },
        {
          prop: 'eng_title',
          label: '分类字段',
          width: '200',
        },
        {
          prop: 'status',
          label: '状态',
          width: '50',
          type: 'switch',
        },
        {
          prop: '',
          label: '操作',
          width: '',
          type: 'but',
        },
      ], // 表头列表
      dataArr: [], // 数据列表
      butnArr: [
        {
          type: '',
          name: '配置列表',
          func: 'openConfig',
        },
        {
          type: '',
          name: '编辑',
          func: '',
        },
        {
          type: 'danger',
          name: '删除',
          func: 'removeDict',
        },
      ], // 数据-操作列表
      count: 0, // 总页数
      formData: {
        page: 1,
        limit: 10,
        title: '',
        status: '',
      },
      showType: '',
      configId: '',
    }
  },
  created() {
  },
  mounted() {
    if (!this.showType) this._loadData()
  },
  methods: {
    _loadData: function () {
      getDict(this.formData).then((res) => {
        const { result } = res

        this.count = result.count
        result.data.forEach(item => {
          item.buttons = JSON.parse(JSON.stringify(this.butnArr))
          item.switch = {
            status: item.status === 1,
            func: 'changeStatus',
            disabled: false,
          }
          item.children && (item.children.forEach(sub => {
            sub.buttons = JSON.parse(JSON.stringify(this.butnArr))
            sub.switch = {
              status: item.status === 1,
              func: 'changeStatus',
              disabled: true,
            }
          }))
          // item.children = []
        })
        this.dataArr = result.data
      })
    },

    // 打开配置列表
    openConfig: function(item) {
      this.configId = String(item.id)
      this.$store.commit('SET_BC', false)
      this.showType = 'dictConfig'
    },

    // 删除字典
    removeDict: function({ id }) {
      delDict(id).then(res => {
        this.$toast(res.message)
        this._loadData()
      })
    },
    // 状态 开关
    changeStatus: function(item) {
      const data = {
        id: item.id,
        status: item.status === 1 ? 0 : 1,
      }
      updDictStatus(data).then(res => {
        this.$toast(res.message)
        this._loadData()
      })
    },

    // 收到关闭回调
    onCallbackBack: function() {
      this.$store.commit('SET_BC', true)
      this.showType = ''
      this._loadData()
    },

    // 收到搜索回调
    onCallbackSearch: function(data) {
      const { title, type } = data
      this.formData.title = title
      this.formData.status = type
      this._loadData()
    },

    // 收到 表格内方法 回调
    onCallbackButton: function(item, func) {
      this[func](item)
    },
    // 收到 表格内 switch 方法回调
    onCallbackSwitch: function(item, func) {
      this[func](item)
    },

    // 收到改变页码回调
    onCallbackPage: function(page) {
      this.formData.page = page
      this._loadData()
    },
    // 收到改变一页数量回调
    onCallbackLimit: function(limit) {
      this.formData.limit = limit
      this._loadData()
    },
  },
}
</script>

<style lang="stylus" scoped></style>
